<template>
  <v-container>
    <v-row>
      <v-col col="12" sm="12" md="12">
        <h2>Name of Children (Write full name and list all)</h2>
        <v-divider></v-divider>
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-btn color="primary" class="mr-4 text--white" @click="openDialog()">Add Info...</v-btn>
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-data-table :headers="headers" :items="desserts" class="elevation-1">
          <template v-slot:item = "row">
            <tr>
              <td nowrap>
                <v-btn class="mx-2" fab dark small color="primary" @click="updates(row.item)">
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="mx-2" fab dark small color="red" @click="deletes(row.item)">
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </td>
              <td>{{row.item.CName}}</td>
              <td>{{row.item.DOB}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Information</span>
          </v-card-title>
          <v-card-text>
          <v-divider></v-divider>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="CName" label="Child's Name" :rules="[vv.init(this.CName), vv.required(), vv.maxlen(255)]" required outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="DOB" label="Date of Birth" :rules="[vv.init(this.DOB), vv.requiredDate()]" type="date" required outlined dense></v-text-field>
                </v-col>
                      
              </v-row>
            </v-form>
          </v-container>
            
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
            <v-btn :disabled="!valid" color="blue darken-1" text @click="savechanges()">Save</v-btn>
          </v-card-actions>
          </v-card>      
        </v-dialog>
      </v-row>
  </v-container>
</template>
<script>
import vldn from "@/js/validation";
export default {
  name: "Chil",
  data: () => ({
    valid: false,
    formTitle: '',
    dialog: false,
    headers: [
      {
        text: 'action',
        align: 'start',
        sortable: false,
        value: 'action',
      },
      {
        text: 'Name',
        sortable: false,
        value: 'CName',
      },
      { text: 'Date of Birth', value: 'DOB' } 
     ],
    desserts: [],
    ID: 0,
    CName: '',
    DOB: '',
    vv: vldn.methods,
  }),
  componets: {
    vldn,
  },
  mounted() {
    this.$api.methods.init();
    this.getchildren();
  },
  methods: {
    getchildren(){
      this.$axios({
        method: 'get',
        url: process.env.VUE_APP_BASE_URL + 'pds/chil',
        headers: {
          Authorization: 'Bearer' + localStorage.getItem('usertoken')
        },
      })
      .then(response => {
        if(response){
          this.desserts = response.data
        }    
      })
      .catch(error => {
        if (error.response) {
          if(error.response.status == 500){
              //
          }
        }    
      })
    },
    openDialog(){
      this.ID = 0;
      this.dialog = true;
    },
    updates(item){
     
      this.ID = item.ID;
      this.CName = item.CName;
      this.DOB = item.DOB;
      this.dialog = true;
    },
    deletes(item){
      this.$axios({
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + 'pds/deletechild',
          headers: {
            Authorization: 'Bearer' + localStorage.getItem('usertoken')
          },
          data: {
            ID:  item.ID
          }
         })
        .then(response => {
          if(response){
            this.getchildren();
            this.ID = 0;
            this.CName = '';
            this.DOB = '';
            
          }    
        })
        .catch(error => {
          if (error.response) {
            if(error.response.status == 500){
              //
            }
          }    
        })
    },
    savechanges(){
      if(this.$refs.form.validate()){
        this.$axios({
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + 'pds/updatechild',
          headers: {
            Authorization: 'Bearer' + localStorage.getItem('usertoken')
          },
          data: {
            ID: this.ID,
            CName: this.CName,
            DOB: this.DOB
          }
         })
        .then(response => {
          if(response){
            this.getchildren();
            this.ID = 0;
            this.CName = '';
            this.DOB = '';
            this.dialog = false;
          }    
        })
        .catch(error => {
          if (error.response) {
            if(error.response.status == 500){
              //
            }
          }    
        })
      }
    }
  }
}
</script>
<style>
  .container{
    max-width: 80%;
  }
</style>
